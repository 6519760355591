// Select Control(Input)
.select__control,
.react-select__control {

  &.select__control--is-focused,
  &.react-select__control--is-focused {
    box-shadow: none;
    border-color: @primary-1;
  }

  .select__indicator svg {
    cursor: pointer;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__single-value {
    color: @b-80;
  }

  .select__placeholder {
    color: @b-80;
  }
}

// Select Menu
.select__menu,
.react-select__menu {

  .select__menu-list,
  .react-select__menu-list {

    .select__option,
    .react-select__option {
      cursor: pointer;

      &.select__option--is-focused {
        background-color: @primary-4;
        color: @primary-1;
      }

      &.select__option--is-selected {
        background-color: @primary-4;
        color: @primary-1;
      }
    }
  }

  .select__menu-list,
  .react-select__menu-list {
    .select__group {
      .select__group-heading {
        margin-bottom: 0.5rem;
        font-weight: bolder;
        font-size: inherit;
      }
    }
  }
}

// Multi Select
.select__multi-value,
.react-select__multi-value {
  color: @b-0;
  border-radius: 3px;
  margin: 0 0.7rem 0 0;

  .select__multi-value__label {
    color: @b-0;
    font-size: 0.85rem;
    border-radius: 7px;
    padding: 0.26rem 0.6rem;
  }

  .select__multi-value__remove {
    padding-left: 0;
    padding-right: 0.5rem;

    &:hover {
      background-color: inherit;
      color: inherit;
    }

    svg {
      height: 0.85rem;
      width: 0.85rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// Select Borderless
.select-borderless {
  .select__control {
    border: 0;

    .select__indicators {
      display: none;
    }
  }
}

// Dark Layout
.dark-layout {
  .select__control {
    .select__indicator svg {
      fill: @b-10;
    }

    .select__indicator span,
    .select__single-value {
      color: @b-10;
    }

    .select__multi-value {
      .select__multi-value__label {
        color: @primary-1;
      }
    }

    .select__multi-value__remove {
      svg {
        fill: @primary-1;
      }

      &:hover {
        background-color: transparent !important;
      }
    }

    &.select__control--is-disabled {
      opacity: 0.5;
    }
  }
}