.@{class-prefix}-components-item {
  transition: 0.4s;

  &-title {
    transition: 0.3s;
  }

  &-img {
    img {
      transition: 0.3s;
    }
  }

  &:hover {
    box-shadow: 0px 16px 32px rgba(0, 16, 247, 0.04);
    background: @b-0 !important;

    .@{class-prefix}-components-item-img {
      img {
        transform: scale(0.9);
      }
    }

    .@{class-prefix}-components-item-title {
      color: @primary-1 !important;
    }
  }
}
