.@{class-prefix}-slider-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .ant-slider {
    flex: 1;
    margin: 0 14px;
  }

  .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 1;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }

  .icon-wrapper-active {
    color: rgba(0, 0, 0, 0.45);
  }
}