.@{class-prefix}-demo-loadmore-list {
    @media @sm {
        .ant-list-item-meta {
            flex: 0 0 100%;
            margin-bottom: 24px
        }
    }
}

@media @sm {
    .ant-list-lg .ant-list-item {
        padding: 16px 0 16px;
    }
}