@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 69, 94, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 6px rgba(255, 69, 94, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 69, 94, 0);
    }
}

.@{class-prefix}-theme-customise {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 4;
    transform: translate(100%, 0);
    transition: 0.4s;

    &.active {
        transform: translate(0%, 0) !important;
        box-shadow: -13px 0px 20px rgba(0, 0, 0, 0.05);

        .@{class-prefix}-theme-customise-button {
            &-bg {
                svg path {
                    fill: @b-0;
                }
            }

            &-icon {
                svg path {
                    fill: @primary-1;
                }
            }
        }
    }

    &-button {
        height: 120px;
        width: 50px;
        position: absolute;
        top: 140px;
        transform: translate(-100%, 0);
        cursor: pointer;

        &-bg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;

            svg path {
                fill: @primary-1;
                transition: 0.4s;
            }
        }

        &-icon {
            position: absolute;
            top: 51px;
            right: 14px;

            svg path {
                fill: @b-0;
                transition: 0.4s;
            }

            >span {
                position: absolute;
                top: -6px;
                right: -6px;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background: @danger-1;
                transform: scale(1);
                box-shadow: 0 0 0 0 rgba(255, 69, 94, 1);
                animation: pulse 2s infinite;
            }
        }
    }

    &-container {
        width: 396px;
        height: 100%;
        display: flex;
        flex-direction: column;

        &-header button {
            margin-right: -24px;
            margin-top: -6px;
        }

        &-body {
            overflow-y: auto;
            overflow-x: hidden;
            flex: 1;

            &::-webkit-scrollbar {
                width: 0;
            }

            &-item {
                &+.@{class-prefix}-theme-customise-container-body-item {
                    margin-top: 24px;
                    padding-top: 24px;
                    border-top: 1px dashed @b-40;
                }

                &-svg {
                    position: relative;
                    height: 101px;
                    border: 2px solid @b-40;
                    transition: 0.4s;
                    cursor: pointer;

                    svg {
                        width: 100%;
                        height: 100%;
                    }

                    &-check {
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(50%, -50%) scale(0);
                        transition: 0.4s;

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &-other {
                        cursor: pointer;
                        border: 2px solid @b-40;
                        height: 33px;
                        transition: 0.4s;

                        svg {
                            width: 100%;
                            height: 100%;
                        }

                        &.active {
                            border-color: @b-100;
                        }
                    }

                    &.active {
                        border-color: @b-100;

                        .@{class-prefix}-theme-customise-container-body-item-svg-check {
                            transform: translate(40%, -30%) scale(1);
                        }
                    }
                }
            }
        }
    }

    @media @sm {
        &-container {
            width: 270px;

            &-body {
                &-item {
                    &-svg {
                        height: 138px;

                        &-other {
                            height: 45px;
                        }
                    }
                }
            }
        }

        &-button {
            transform: translate(-100%, -50%) scale(0.9);
            transform-origin: right;
        }
    }
}

// RTL
html[dir="rtl"] {
    .@{class-prefix}-theme-customise {
        left: 0;
        right: auto;
        transform: translate(-100%, 0);

        &.active {
            box-shadow: 13px 0px 20px rgba(0, 0, 0, 0.05);

            &-container {
                &-header button {
                    margin-right: -24px;
                    margin-top: -6px;
                }
            }
        }

        &-button {
            transform: translate(100%, 0%) scale(-1, 1);

            @media @sm {
                transform: translate(0%, -50%) scale(-0.9, 0.9);
            }
        }

        &-container {
            &-header button {
                margin-left: -24px;
                margin-right: 0;
            }
        }
    }
}