.@{class-prefix}-faq-tabs .ant-tabs-nav {
  margin-bottom: 0;

  .ant-tabs-tab-active {
    svg {
      fill: @primary-1  !important;
    }

    span {
      color: @primary-1  !important;
    }
  }

  .ant-tabs-tab-active {

    svg,
    span {
      transition: 0.4s;
    }
  }
}