.@{class-prefix}-grid-row-bg {
    background-image: linear-gradient(90deg, #B2BEC3 4.16666667%, transparent 0, transparent 8.33333333%, #B2BEC3 0, #B2BEC3 12.5%, transparent 0, transparent 16.66666667%, #B2BEC3 0, #B2BEC3 20.83333333%, transparent 0, transparent 25%, #B2BEC3 0, #B2BEC3 29.16666667%, transparent 0, transparent 33.33333333%, #B2BEC3 0, #B2BEC3 37.5%, transparent 0, transparent 41.66666667%, #B2BEC3 0, #B2BEC3 45.83333333%, transparent 0, transparent 50%, #B2BEC3 0, #B2BEC3 54.16666667%, transparent 0, transparent 58.33333333%, #B2BEC3 0, #B2BEC3 62.5%, transparent 0, transparent 66.66666667%, #B2BEC3 0, #B2BEC3 70.83333333%, transparent 0, transparent 75%, #B2BEC3 0, #B2BEC3 79.16666667%, transparent 0, transparent 83.33333333%, #B2BEC3 0, #B2BEC3 87.5%, transparent 0, transparent 91.66666667%, #B2BEC3 0, #B2BEC3 95.83333333%, transparent 0);
    margin-bottom: 8px;
    overflow: hidden;
}

.@{class-prefix}-grid-col {
    border: none;
    color: @b-0;
    font-size: 18px;
    line-height: 2;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    opacity: 0.75;
    padding: 30px 0px;
}

.@{class-prefix}-mark {
    ul {
        >li {
            list-style-type: circle;
            margin-left: 20px;
            padding-left: 4px;
        }
    }
}