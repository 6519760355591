.@{class-prefix}-error-content {
  min-height: calc(100vh - 189px);

  &-title {
    font-size: 22vw;
    line-height: 1.2;

    @media @lg {
      font-size: 32vw;
    }

    @media @sm {
      font-size: 45vw;
    }
  }

  .@{class-prefix}-comingsoon-timer-item {
    min-width: 200px;

    span {
      &:nth-child(1) {
        font-size: 100px;
      }
    }

    @media @lg {
      min-width: 16vw;

      span {
        &:nth-child(1) {
          font-size: 8vw;
        }
      }
    }

    @media @sm {
      min-width: 18vw;

      span {
        &:nth-child(1) {
          font-size: 11vw;
          margin-bottom: -8px;
        }

        &:nth-child(2) {
          font-size: 3vw;
        }
      }
    }
  }
}
