.@{class-prefix}-card-1 {
    min-height: 120px;
}

.@{class-prefix}-card-2 {
    min-height: 202px;
}

.@{class-prefix}-card-3 {
    min-height: 272px;
}

.@{class-prefix}-card-4 {
    min-height: 342px;
}

.@{class-prefix}-card-5 {
    min-height: 352px;
}

.@{class-prefix}-card-6 {
    min-height: 424px;
}

.@{class-prefix}-card-7 {
    min-height: 576px;
}

.@{class-prefix}-card-8 {
    min-height: 656px;
}